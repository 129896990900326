import post from '../helpers/request/post';
import {order__lastHundred as targetURL} from '../endpointURLs';

type order__lastHundred = {
};

export default function (
	payload: order__lastHundred,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}