import { PayloadBody } from './types';

export default function (
	body: PayloadBody = {},
	files?: FileList,
	filesKey?: string | undefined
): FormData {
	const formData = new FormData();

	function appendToFormData(data: any, parentKey = '') {
		if (Array.isArray(data)) {
			data.forEach((item, index) => {
				const propName = parentKey ? `${parentKey}[${index}]` : String(index);
				appendToFormData(item, propName);
			});
		} else if (typeof data === 'object' && data !== null) {
			Object.entries(data).forEach(([key, value]) => {
				const propName = parentKey ? `${parentKey}[${key}]` : key;
				appendToFormData(value, propName);
			});
		} else {
			formData.append(String(parentKey), String(data));
		}
	}

	appendToFormData(body);

	if (files && filesKey) {
		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			formData.append(filesKey, file);
		}
	}

	return formData;
}
