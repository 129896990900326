<template>
    <aside class='aside'>
        <div class='aside__links'>
            <router-link
                v-for='link in links'
                class='aside__link'
                :key='link.to'
                :to='link.to'
            >
                {{ link.name }}
            </router-link>
        </div>

        <button
            v-if='!wasPublishButtonClicked && auth.me.value.role === "superAdmin"'
            @click='publish()'
        >
            Publikuoti
        </button>
    </aside>
</template>

<script setup>
    import {computed, ref} from 'vue';
    import build__publish from '@/SDK/requests/build__publish';
    import useAuth from '@/composables/useAuth';

    const LINK_KATALOGAI = '/katalogai';
    const LINK_ORDERS = '/uzsakymai';

    const auth = useAuth();

    const wasPublishButtonClicked = ref(false);

    const allLinks = [
        {
            to: LINK_KATALOGAI,
            name: 'Katalogai',
        },

        {
            to: '/produktai',
            name: 'Produktai',
        },

        {
            to: '/nuolaidos',
            name: 'Nuolaidos',
        },

        {
            to: '/vertimai',
            name: 'Vertimai',
        },

        {
            to: '/konstantos',
            name: 'Konstantos',
        },

        {
            to: '/partneriai',
            name: 'Partneriai',
        },

        {
            to: '/duk',
            name: 'D.U.K.'
        },

        {
            to: '/adminai',
            name: 'Adminai',
        },

        {
            to: LINK_ORDERS,
            name: 'Uzsakymai',
        },
    ];

    const links = computed(() => {
        if (auth.me.value.role === 'superAdmin') {
            return allLinks;
        }

        switch (auth.me.value.role) {
            case 'superAdmin': {
                return allLinks;
            }

            case 'photoImporter': {
                return allLinks.filter((link) => {
                    return [
                        LINK_KATALOGAI,
                    ].includes(link.to);
                });
            }

            case 'photoImporterAndOrdersEditor': {
                return allLinks.filter((link) => {
                    return [
                        LINK_KATALOGAI,
                        LINK_ORDERS,
                    ].includes(link.to);
                });
            }

            default: {
                return [];
            }
        }
    });

    function publish() {
        wasPublishButtonClicked.value = true;

        build__publish({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        });
    }
</script>

<style lang='scss' scoped>
    .aside {
        background-color: lightgray;
        height: 100%;
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;


        &__link {
            display:         block;
            width:           100%;
            text-decoration: none;
            color:           #2c2ca5;
            font-size: 20px;
            padding: 10px 30px;

            &:hover {
                background-color: white;
            }
        }
    }
</style>
