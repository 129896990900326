export const BASE_API_PATH = 'https://api.tavoakimirka.lt';

export const system__joinWithGoogle = `${BASE_API_PATH}/v1/system/join-with-google`;
export const translation__delete = `${BASE_API_PATH}/v1/translation/delete`;
export const translation__getAll = `${BASE_API_PATH}/v1/translation/get-all`;
export const translation__update = `${BASE_API_PATH}/v1/translation/update`;
export const product__create = `${BASE_API_PATH}/v1/product/create`;
export const product__delete = `${BASE_API_PATH}/v1/product/delete`;
export const product__getAll = `${BASE_API_PATH}/v1/product/get-all`;
export const product__update = `${BASE_API_PATH}/v1/product/update`;
export const product__uploadPhoto = `${BASE_API_PATH}/v1/product/upload-photo`;
export const eventCatalogue__create = `${BASE_API_PATH}/v1/event-catalogue/create`;
export const eventCatalogue__delete = `${BASE_API_PATH}/v1/event-catalogue/delete`;
export const eventCatalogue__getAll = `${BASE_API_PATH}/v1/event-catalogue/get-all`;
export const eventCatalogue__uploadBackgroundImage = `${BASE_API_PATH}/v1/event-catalogue/upload-background-image`;
export const event__create = `${BASE_API_PATH}/v1/event/create`;
export const event__delete = `${BASE_API_PATH}/v1/event/delete`;
export const event__deleteImage = `${BASE_API_PATH}/v1/event/delete-image`;
export const event__getAllImageNames = `${BASE_API_PATH}/v1/event/get-all-image-names`;
export const event__getAllInCatalogue = `${BASE_API_PATH}/v1/event/get-all-in-catalogue`;
export const event__search = `${BASE_API_PATH}/v1/event/search`;
export const event__uploadBackgroundImage = `${BASE_API_PATH}/v1/event/upload-background-image`;
export const event__uploadCustomImage = `${BASE_API_PATH}/v1/event/upload-custom-image`;
export const event__uploadEventImages = `${BASE_API_PATH}/v1/event/upload-event-images`;
export const event__userUploadedImages = `${BASE_API_PATH}/v1/event/user-uploaded-images`;
export const faq__create = `${BASE_API_PATH}/v1/faq/create`;
export const faq__delete = `${BASE_API_PATH}/v1/faq/delete`;
export const faq__getAll = `${BASE_API_PATH}/v1/faq/get-all`;
export const faq__update = `${BASE_API_PATH}/v1/faq/update`;
export const constant__getAll = `${BASE_API_PATH}/v1/constant/get-all`;
export const constant__update = `${BASE_API_PATH}/v1/constant/update`;
export const order__create = `${BASE_API_PATH}/v1/order/create`;
export const order__findById = `${BASE_API_PATH}/v1/order/find-by-id`;
export const order__lastHundred = `${BASE_API_PATH}/v1/order/last-hundred`;
export const partner__getAllPartnerLogoNames = `${BASE_API_PATH}/v1/partner/get-all-partner-logo-names`;
export const partner__remove = `${BASE_API_PATH}/v1/partner/remove`;
export const partner__upload = `${BASE_API_PATH}/v1/partner/upload`;
export const build__publish = `${BASE_API_PATH}/v1/build/publish`;
export const admin__create = `${BASE_API_PATH}/v1/admin/create`;
export const admin__delete = `${BASE_API_PATH}/v1/admin/delete`;
export const admin__getAll = `${BASE_API_PATH}/v1/admin/get-all`;
export const admin__login = `${BASE_API_PATH}/v1/admin/login`;
export const contact__contact = `${BASE_API_PATH}/v1/contact/contact`;
export const discount__create = `${BASE_API_PATH}/v1/discount/create`;
export const discount__delete = `${BASE_API_PATH}/v1/discount/delete`;
export const discount__findOne = `${BASE_API_PATH}/v1/discount/find-one`;
export const discount__getAll = `${BASE_API_PATH}/v1/discount/get-all`;
