import post from '../helpers/request/post';
import {product__create as targetURL} from '../endpointURLs';

type product__create = {
lt: {
    title: string;
    description: string;
    meta: string;
};
en: {
    title: string;
    description: string;
    meta: string;
};
    priceCents: number;
};

export default function (
	payload: product__create,
	headers = {},
	files: FileList
) {
	return post(targetURL, payload, headers, 'multipart/form-data', files, 'thumbnail');
}