import post from '../helpers/request/post';
import {order__findById as targetURL} from '../endpointURLs';

type order__findById = {
    orderID: string;
};

export default function (
	payload: order__findById,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}