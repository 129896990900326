import del from '../helpers/request/del';
import {discount__delete as targetURL} from '../endpointURLs';

type discount__delete = {
    discountID: string;
};

export default function (
	payload: discount__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}