import get from '../helpers/request/get';
import {faq__getAll as targetURL} from '../endpointURLs';

type faq__getAll = {
};

export default function (
	payload: faq__getAll,
	headers = {},
) {
	return get(targetURL, payload, headers);
}