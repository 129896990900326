<template>
    <div>
        <h1>D.U.K.</h1>

        <table>
            <thead>
                <tr>
                    <th>LT Klausimas</th>
                    <th>LT Atsakymas</th>
                    <th>EN Klausimas</th>
                    <th>EN Atsakymas</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th>
                        <input v-model='newFaqItem.lt.question' type='text' />
                    </th>

                    <th>
                        <input v-model='newFaqItem.lt.answer' type='text' />
                    </th>

                    <th>
                        <input v-model='newFaqItem.en.question' type='text' />
                    </th>

                    <th>
                        <input v-model='newFaqItem.en.answer' type='text' />
                    </th>

                    <th>
                        <button @click='createFaqItem'>Sukurti</button>
                    </th>
                </tr>

                <template
                    v-for='faqItem in faqs'
                    :key='faqItem._id'
                >
                    <tr>
                        <td>{{ faqItem.lt.question }}</td>
                        <td>{{ faqItem.lt.answer }}</td>
                        <td>{{ faqItem.en.question }}</td>
                        <td>{{ faqItem.en.answer }}</td>

                        <td>
                            <button @click='deleteFaqItem(faqItem)'>Trinti</button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import faq__getAll from '../SDK/requests/faq__getAll';
    import faq__create from '@/SDK/requests/faq__create';
    import faq__delete from '@/SDK/requests/faq__delete';

    const faqs = ref([]);

    const newFaqItem = ref({
        lt: {
            question: '',
            answer: '',
        },

        en: {
            question: '',
            answer: '',
        },
    });

    async function loadFaqs() {
        faqs.value = (await faq__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    }

    function resetCreateFaqForm() {
        newFaqItem.value.lt.question = '';
        newFaqItem.value.lt.answer = '';
        newFaqItem.value.en.question = '';
        newFaqItem.value.en.answer = '';
    }

    onBeforeMount(loadFaqs);

    async function deleteFaqItem(faqItem) {
        await faq__delete({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            faqId: faqItem._id,
        });

        loadFaqs();
    }

    async function createFaqItem() {
        await faq__create({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            ...newFaqItem.value,
        });

        resetCreateFaqForm();
        loadFaqs();
    }
</script>
