import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
import Katalogai from './pages/Katalogai.vue';
import Konstantos from './pages/Konstantos.vue';
import Vertimai from './pages/Vertimai.vue';
import Duk from './pages/Duk.vue';
import Partneriai from './pages/Partneriai.vue';
import Adminai from './pages/Adminai.vue';
import Produktai from './pages/Produktai.vue';
import Nuolaidos from './pages/Nuolaidos.vue';
import Uzsakymai from './pages/Uzsakymai.vue';

const routes = [
	{
		path: '/',
		component: Home
	},

	{
		path: '/produktai',
		component: Produktai,
	},

	{
		path: '/nuolaidos',
		component: Nuolaidos,
	},

	{
		path: '/katalogai',
		component: Katalogai
	},

	{
		path: '/konstantos',
		component: Konstantos
	},

	{
		path: '/vertimai',
		component: Vertimai,
	},

	{
		path: '/partneriai',
		component: Partneriai,
	},

	{
		path: '/duk',
		component: Duk,
	},

	{
		path: '/adminai',
		component: Adminai,
	},

	{
		path: '/uzsakymai',
		component: Uzsakymai,
	},
];

export default createRouter({
	history: createWebHistory(),
	routes,
});
