import get from '../helpers/request/get';
import {eventCatalogue__getAll as targetURL} from '../endpointURLs';

type eventCatalogue__getAll = {
};

export default function (
	payload: eventCatalogue__getAll,
	headers = {},
) {
	return get(targetURL, payload, headers);
}