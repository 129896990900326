import post from '../helpers/request/post';
import {admin__create as targetURL} from '../endpointURLs';

type admin__create = {
    username: string;
    password: string;
    role: string;
};

export default function (
	payload: admin__create,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}