import getCookie from '../cookie/get';

export default async function request<T>(
	url: string,
	options: RequestInit,
): Promise<T> {
	if (!options.headers) {
		options.headers = {};
	}

	if (typeof document !== 'undefined') {
		const jwtCookie = getCookie("JWT");

		if (jwtCookie) {
			(options.headers as Record<string, string>)["Authorization"] = `Bearer ${jwtCookie}`;
		}
	}

	const response = await fetch(url, options);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	return response.json();
}
