import post from '../helpers/request/post';
import {build__publish as targetURL} from '../endpointURLs';

type build__publish = {
};

export default function (
	payload: build__publish,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}