import get from '../helpers/request/get';
import {translation__getAll as targetURL} from '../endpointURLs';

type translation__getAll = {
};

export default function (
	payload: translation__getAll,
	headers = {},
) {
	return get(targetURL, payload, headers);
}