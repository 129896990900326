<template>
    <div>
        <h1>Vertimai</h1>

        <table>
            <thead>
                <tr>
                    <th>LT</th>
                    <th>EN</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for='translation in translations'
                    :key='translation.key'
                >
                    <td>
                        <textarea
                            v-if="translation.key.startsWith('page_text_')"
                            v-model='translation.lt'
                            @input='translation.isDirty = true'
                        ></textarea>

                        <input
                            v-else
                            type='text'
                            v-model='translation.lt'
                            @input='translation.isDirty = true'
                        />
                    </td>

                    <td>
                        <textarea
                            v-if="translation.key.startsWith('page_text_')"
                            v-model='translation.en'
                            @input='translation.isDirty = true'
                        ></textarea>

                        <input
                            v-else
                            type='text'
                            v-model='translation.en'
                            @input='translation.isDirty = true'
                        />
                    </td>

                    <td>
                        <button
                            v-if='translation.isDirty'
                            @click='updateTranslation(translation)'
                        >
                            Atnaujinti
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import translation__getAll from '../SDK/requests/translation__getAll';
    import translation__update from '../SDK/requests/translation__update';

    const translations = ref([]);

    onBeforeMount(async () => {
        translations.value = (await translation__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    });

    function updateTranslation(updatedTranslation) {
        updatedTranslation.isDirty = false;

        translation__update({
            ...updatedTranslation,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            translationId: updatedTranslation._id,
        });
    }
</script>
