import del from '../helpers/request/del';
import {partner__remove as targetURL} from '../endpointURLs';

type partner__remove = {
    targetPartnerFileName: string;
};

export default function (
	payload: partner__remove,
	headers = {},
) {
	return del(targetURL, payload, headers);
}