import post from '../helpers/request/post';
import {eventCatalogue__uploadBackgroundImage as targetURL} from '../endpointURLs';

type eventCatalogue__uploadBackgroundImage = {
    catalogueID: string;
};

export default function (
	payload: eventCatalogue__uploadBackgroundImage,
	headers = {},
	files: FileList
) {
	return post(targetURL, payload, headers, 'multipart/form-data', files, 'backgroundImage');
}