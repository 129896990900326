import post from '../helpers/request/post';
import {discount__create as targetURL} from '../endpointURLs';

type discount__create = {
    code: string;
    type: string;
    discountAmountPercentage: number;
    endDate: string;
    remainingUses: number;
};

export default function (
	payload: discount__create,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}