import del from '../helpers/request/del';
import {event__delete as targetURL} from '../endpointURLs';

type event__delete = {
    eventID: string;
};

export default function (
	payload: event__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}