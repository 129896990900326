import put from '../helpers/request/put';
import {constant__update as targetURL} from '../endpointURLs';

type constant__update = {
    constantId: string;
    key: string;
    value: string;
};

export default function (
	payload: constant__update,
	headers = {},
) {
	return put(targetURL, payload, headers, 'application/json');
}