import get from '../helpers/request/get';
import {partner__getAllPartnerLogoNames as targetURL} from '../endpointURLs';

type partner__getAllPartnerLogoNames = {
};

export default function (
	payload: partner__getAllPartnerLogoNames,
	headers = {},
) {
	return get(targetURL, payload, headers);
}