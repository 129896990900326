import post from '../helpers/request/post';
import {partner__upload as targetURL} from '../endpointURLs';

type partner__upload = {
    name: string;
};

export default function (
	payload: partner__upload,
	headers = {},
	files: FileList
) {
	return post(targetURL, payload, headers, 'multipart/form-data', files, 'logo');
}