<template>
    <div class='app'>
        <template v-if='auth.me.value.JWT'>
            <Aside />

            <main class='app__main'>
                <button @click='auth.logout()'>
                    Log out
                </button>

                <router-view />
            </main>
        </template>

        <form
            v-else
            class='app__login-form'
            @submit.prevent='auth.login(username, password)'
        >
            <input
                v-model='username'
                type='text'
                placeholder='Username'
            />

            <input
                v-model='password'
                type='password'
                placeholder='Password'
            />

            <button type='submit'>
                Login
            </button>
        </form>
    </div>
</template>

<script setup>
    import Aside from './components/Aside';
    import {ref} from 'vue';
    import useAuth from '@/composables/useAuth';

    const auth = useAuth();

    const username = ref();
    const password = ref();
</script>

<style lang='scss'>
    html, body, #app {
        padding: 0;
        margin: 0;
        height: 100%;
    }

    table {
        width: 100%;

        table {
            padding-left: 30px;
        }

        tr {
            &:hover {
                background-color: lightgray;
            }

            tr {
                &:hover {
                    background-color: white;
                }
            }
        }

        td {
            border-top: 1px solid lightgray;
        }

        td, th {
            padding: 5px;
            text-align: left;
        }
    }
</style>

<style lang='scss' scoped>
    .app {
        height: 100%;
        display: flex;

        &__main {
            flex-grow: 1;
            padding: 40px;
        }

        &__login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 20px;
        }
    }
</style>
