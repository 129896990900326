import post from '../helpers/request/post';
import {faq__create as targetURL} from '../endpointURLs';

type faq__create = {
lt: {
    question: string;
    answer: string;
};
en: {
    question: string;
    answer: string;
};
};

export default function (
	payload: faq__create,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}