import del from '../helpers/request/del';
import {product__delete as targetURL} from '../endpointURLs';

type product__delete = {
    productID: string;
};

export default function (
	payload: product__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}