import put from '../helpers/request/put';
import {product__update as targetURL} from '../endpointURLs';

type product__update = {
    productID: string;
lt: {
    title: string;
    description: string;
    meta: string;
};
en: {
    title: string;
    description: string;
    meta: string;
};
    priceCents: number;
};

export default function (
	payload: product__update,
	headers = {},
) {
	return put(targetURL, payload, headers, 'application/json');
}