import post from '../helpers/request/post';
import {event__getAllImageNames as targetURL} from '../endpointURLs';

type event__getAllImageNames = {
    eventId: string;
};

export default function (
	payload: event__getAllImageNames,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}