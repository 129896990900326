import post from '../helpers/request/post';
import {event__create as targetURL} from '../endpointURLs';

type event__create = {
    name: string;
    nameSlug: string;
    dateStarted: string;
    imageIDs: string[];
    catalogueID: string;
    isInvisible: boolean;
    noWatermark: boolean;
};

export default function (
	payload: event__create,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}