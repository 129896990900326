<template>
    <div>
        <h1>Katalogai</h1>

        <progress
            v-if="isEventPhotosBeingUploaded"
            :max="imagesBeingUploadedTotalCount"
            :value="totalImagesUploaded"
        ></progress>

        <table>
            <thead>
                <tr>
                    <th>Katalogas</th>
                    <th>Nuotrauka</th>
                    <th>Be watermark</th>
                    <th>Nematomas</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <input
                            v-model='newCatalogue.name'
                            type='text'
                        />
                    </td>

                    <td title='Pirma ikelk kataloga, tada eis ikelti nuotrauka'>
                        -
                    </td>

                    <td>
                        <input
                            type="checkbox"
                            v-model="newCatalogue.noWatermark"
                        />
                    </td>

                    <td>
                        <input
                            type="checkbox"
                            v-model="newCatalogue.isInvisible"
                        />
                    </td>

                    <td>
                        <button @click='createEventCatalogue'>
                            Ikelti
                        </button>
                    </td>
                </tr>

                <template
                    v-for='catalogue in catalogues'
                    :key='catalogue._id'
                >
                    <tr>
                        <td>
                            <button @click='toggleCatalogueEventsIsOpened(catalogue)'>
                                V
                            </button>

                            {{ catalogue.name }}
                        </td>

                        <td>
                            <label>
                                <img
                                    :src='`${BASE_API_PATH}/images/catalogues/${catalogue._id}.jpg?r=${catalogue.imgRenderCount}`'
                                    :style="{
                                        width: '100px',
                                        height: '70px',
                                        'object-fit': 'contain'
                                    }"
                                />

                                <input
                                    type='file'
                                    style="opacity: 0;width: 0;height: 0;"
                                    @change='uploadNewCatalogueImage(catalogue, $event)'
                                />
                            </label>
                        </td>

                        <td>
                            {{ catalogue.noWatermark }}
                        </td>

                        <td>
                            {{ catalogue.isInvisible }}
                            (<a target="_blank" :href="`https://tavoakimirka.lt/lt/katalogas/${catalogue.nameSlug}`">atidaryti</a>)
                        </td>

                        <td>
                            <button @click='deleteCatalogue(catalogue)'>
                                Trinti
                            </button>
                        </td>
                    </tr>

                    <tr v-if='catalogue.isEventsOpened'>
                        <td colspan='5'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Renginys</th>
                                        <th>Nuotrauka</th>
                                        <th>Data</th>
                                        <th>Be watermark</th>
                                        <th>Nematomas</th>
                                        <th>Veiksmai</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                v-model='newEvent.name'
                                                type='text'
                                            />
                                        </td>

                                        <td title='Pirma ikelk rengini, tada eis ikelti nuotrauka'>
                                            -
                                        </td>

                                        <td>
                                            <input
                                                v-model='newEvent.dateStarted'
                                                type='date'
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                v-model="newEvent.noWatermark"
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                v-model="newEvent.isInvisible"
                                            />
                                        </td>

                                        <td>
                                            <button @click='createEvent(catalogue, catalogue._id)'>
                                                Ikelti
                                            </button>
                                        </td>
                                    </tr>

                                    <template
                                        v-for='event in catalogue.events'
                                        :key='event._id'
                                    >
                                        <tr>
                                            <td>
                                                <button @click='toggleEventImagesGallery(event)'>
                                                    V
                                                </button>
                                                {{ event.name }}
                                            </td>

                                            <td>
                                                <label>
                                                    <img
                                                        :src='`${BASE_API_PATH}/images/events/${event._id}.jpg?r=${event.imgRenderCount}`'
                                                        :style="{
                                                            width: '100px',
                                                            height: '70px',
                                                            'object-fit': 'contain'
                                                        }"
                                                    />

                                                    <input
                                                        type='file'
                                                        style="opacity: 0;width: 0;height: 0;"
                                                        @change='uploadNewEventBackgroundImage(event, $event)'
                                                    />
                                                </label>
                                            </td>

                                            <td>{{ event.dateStarted }}</td>
                                            <td>{{ event.noWatermark }}</td>

                                            <td>
                                                {{ event.isInvisible }}
                                                (<a target="_blank" :href="`https://tavoakimirka.lt/lt/katalogas/${catalogue.nameSlug}/${event._id}`">atidaryti</a>)
                                            </td>

                                            <td>
                                                <button @click='deleteEvent(catalogue, event)'>
                                                    Trinti
                                                </button>

                                                <div>
                                                    <div v-if="isEventPhotosBeingUploaded">
                                                        Keliamos nuotraukos
                                                    </div>

                                                    <label v-else>
                                                        <input
                                                            style="visibility:hidden;"
                                                            type="file"
                                                            multiple
                                                            @change='uploadEventImages(event, $event, catalogue.noWatermark || event.noWatermark || false)'
                                                        >

                                                        <span
                                                            style='background-color: #e8e8e8;border: 1px solid lightgray; padding: 5px; border-radius: 4px; display: inline-block;'
                                                        >
                                                            Ikelti renginio nuotraukas
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-if='event.images'>
                                            <td colspan='6'>
                                                <img
                                                    v-for='imageName in event.images'
                                                    :key='imageName'
                                                    :src='`${BASE_API_PATH}/images/events/${event._id}/thumbnail/${imageName}`'
                                                    @click="promptDeleteImage(event, event._id, imageName)"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {onBeforeMount, ref} from 'vue';
    import eventCatalogue__create from '@/SDK/requests/eventCatalogue__create';
    import eventCatalogue__getAll from '@/SDK/requests/eventCatalogue__getAll';
    import event__getAllInCatalogue from '@/SDK/requests/event__getAllInCatalogue';
    import event__create from '@/SDK/requests/event__create';
    import eventCatalogue__uploadBackgroundImage from '@/SDK/requests/eventCatalogue__uploadBackgroundImage';
    import eventCatalogue__delete from '@/SDK/requests/eventCatalogue__delete';
    import event__delete from '@/SDK/requests/event__delete';
    import event__uploadBackgroundImage from '@/SDK/requests/event__uploadBackgroundImage';
    import event__uploadEventImages from '@/SDK/requests/event__uploadEventImages';
    import event__getAllImageNames from '@/SDK/requests/event__getAllImageNames';
    import { BASE_API_PATH } from '@/SDK/endpointURLs';
    import event__deleteImage from '@/SDK/requests/event__deleteImage';
    import slugify from '@/helpers/shared/string/slugify';
    import forEachAsync from '@/helpers/shared/array/forEachAsync';

    const isEventPhotosBeingUploaded = ref(false);
    const imagesBeingUploadedTotalCount = ref(0);
    const totalImagesUploaded = ref(0);

    const newCatalogue = ref({
        name: undefined,
        isInvisible: false,
        noWatermark: false,
    });

    const newEvent = ref({
        name: undefined,
        dateStarted: undefined,
        isInvisible: false,
        noWatermark: false,
    });

    function resetNewCatalogue() {
        newCatalogue.value.name = undefined;
        newCatalogue.value.isInvisible = false;
        newCatalogue.value.noWatermark = false;
    }

    function resetNewEvent() {
        newEvent.value.name = undefined;
        newEvent.value.dateStarted = undefined;
        newEvent.value.isInvisible = false;
        newEvent.value.noWatermark = false;
    }

    const catalogues = ref([]);

    async function promptDeleteImage(event, eventID, imageID) {
        const isDeleteAccepted = confirm("Istrinti nuotrauka?");

        if (!isDeleteAccepted) {
            return;
        }

        await event__deleteImage({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            imageID,
            eventID,
        });

        loadEventImages(event);
    }

    onBeforeMount(loadCatalogues);

    async function loadCatalogues () {
        catalogues.value = (await eventCatalogue__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    }

    async function loadEventImages(event) {
        event.images = (await event__getAllImageNames({
            eventId: event._id,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    }

    async function toggleEventImagesGallery(event) {
        if (Array.isArray(event.images) && event.images.length) {
            event.images = [];

            return;
        }

        loadEventImages(event);
    }

    async function deleteEvent(catalogue, event) {
        await event__delete({
            eventID: event._id,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        });

        loadCatalogueEvents(catalogue);
    }

    async function deleteCatalogue(catalogue) {
        await eventCatalogue__delete({
            catalogueID: catalogue._id,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        });

        loadCatalogues();
    }

    async function createEventCatalogue() {
        await eventCatalogue__create({
            ...newCatalogue.value,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        });

        resetNewCatalogue();
        loadCatalogues();
    }

    async function createEvent(catalogue, targetCatalogueId) {
        await event__create({
            ...newEvent.value,
            catalogueID: targetCatalogueId,
            imageIDs: [],
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            nameSlug: slugify(newEvent.value.name),
        });

        loadCatalogueEvents(catalogue);
        resetNewEvent();
    }

    async function uploadEventImages(event, uploadEvent, noWatermark) {
        isEventPhotosBeingUploaded.value = true;

        try {
            const imagesArray = Array.from(uploadEvent.target.files);

            imagesBeingUploadedTotalCount.value = imagesArray.length;

            await forEachAsync(imagesArray, async (image) => {
                totalImagesUploaded.value = totalImagesUploaded.value + 1;

                await event__uploadEventImages({
                    projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
                    eventId: event._id,
                    noWatermark,
                }, {}, [image]);
            });
        } catch (e) {
            alert('Klaida ikeliant renginio nuotraukas');
        }

        isEventPhotosBeingUploaded.value = false;
    }

    async function uploadNewEventBackgroundImage(event, uploadEvent) {
        await event__uploadBackgroundImage({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            eventId: event._id,
        }, {}, uploadEvent.target.files);

        event.imgRenderCount = typeof event.imgRenderCount === 'number'
            ? event.imgRenderCount++
            : 0;
    }

    async function uploadNewCatalogueImage(catalogue, event) {
        await eventCatalogue__uploadBackgroundImage({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            catalogueID: catalogue._id,
        }, {}, event.target.files);

        catalogue.imgRenderCount = typeof catalogue.imgRenderCount === 'number'
            ? catalogue.imgRenderCount++
            : 0;
    }

    async function loadCatalogueEvents(targetCatalogue) {
        const eventsInCatalogueResponse = await event__getAllInCatalogue({
            targetCatalogueID: targetCatalogue._id,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        });

        targetCatalogue.events = eventsInCatalogueResponse.data;
    }

    async function toggleCatalogueEventsIsOpened(targetCatalogue) {
        if (typeof targetCatalogue.isEventsOpened === 'undefined') {
            targetCatalogue.isEventsOpened = true;
            loadCatalogueEvents(targetCatalogue);

            return;
        }

        targetCatalogue.isEventsOpened = !targetCatalogue.isEventsOpened;
    }
</script>
