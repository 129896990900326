<template>
    <div class='partneriai'>
        <h1>Partneriai</h1>

        <input
            type='text'
            placeholder='Naujo partnerio pavadinimas'
            v-model='newPartnerName'
        />

        <label v-if='newPartnerName'>
            Ikelti

            <input
                style="visibility: hidden;"
                type="file"
                @change='uploadPartnerLogo'
            />
        </label>

        <div class='partneriai__grid'>
            <img
                v-for='logoName in partnerLogoNames'
                :key='logoName'
                :src='`${BASE_API_PATH}/images/partners/${logoName}`'
                @click='promptDelete(logoName)'
            />
        </div>
    </div>
</template>

<script setup>
    import {onBeforeMount, ref} from 'vue';
    import partner__upload from '@/SDK/requests/partner__upload';
    import partner__getAllPartnerLogoNames from '@/SDK/requests/partner__getAllPartnerLogoNames';
    import partner__remove from '@/SDK/requests/partner__remove';
    import {BASE_API_PATH} from '@/SDK/endpointURLs';

    const newPartnerName = ref();
    const partnerLogoNames = ref([]);

    async function loadPartnerLogoNames () {
        partnerLogoNames.value = (await partner__getAllPartnerLogoNames({})).data;
    }

    onBeforeMount(loadPartnerLogoNames);

    async function promptDelete(logoName) {
        const isDeleteAccepted = confirm("Istrinti partneri?");

        if (!isDeleteAccepted) {
            return;
        }

        await partner__remove({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            targetPartnerFileName: logoName,
        });

        loadPartnerLogoNames();
    }

    async function uploadPartnerLogo(uploadEvent) {
        await partner__upload({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            name: newPartnerName.value,
        }, {}, uploadEvent.target.files);

        newPartnerName.value = '';
        loadPartnerLogoNames();
    }
</script>

<style lang='scss'>
    .partneriai__grid {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 100px));
        grid-gap: 10px;

        img {
            filter: grayscale(100%);
            transition: filter 0.3s;

            &:hover {
                cursor: pointer;
                filter: unset;
            }
        }
    }
</style>
