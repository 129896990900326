import getCookie from '../helpers/browser/cookie/get';
import {ref} from 'vue';
import admin__login from '@/SDK/requests/admin__login';
import setCookie from '@/helpers/browser/cookie/set';
import removeCookie from '@/helpers/browser/cookie/remove';

const COOKIE_ADMIN_ROLE_KEY = 'adminRole';
const COOKIE_ADMIN_JWT_KEY = 'adminJWT';

type Me = {
	role: string | undefined;
	JWT: string | undefined;
}

const me = ref<Me>({
	role: undefined,
	JWT: undefined,
});

export default function () {
	if (!me.value.JWT) {
		me.value.JWT = getCookie(COOKIE_ADMIN_JWT_KEY);
		me.value.role = getCookie(COOKIE_ADMIN_ROLE_KEY);
	}

	async function login(username: string, password: string) {
		const loginResponse = await admin__login({
			username,
			password,
		});

		// @ts-ignore
		const responseData = loginResponse.data;

		if (responseData) {
			setCookie(COOKIE_ADMIN_JWT_KEY, responseData.JWT);
			setCookie(COOKIE_ADMIN_ROLE_KEY, responseData.role);
			me.value.JWT = responseData.JWT;
			me.value.role = responseData.role;
		}
	}

	function logout () {
		removeCookie(COOKIE_ADMIN_JWT_KEY);
		removeCookie(COOKIE_ADMIN_ROLE_KEY);
		me.value.JWT = undefined;
		me.value.role = undefined;
	}

	return {
		login,
		logout,
		me,
	};
}
