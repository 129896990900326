import request from './request';
import buildRequestPayloadMultipartForm from './buildRequestPayloadMultipartForm';
import buildRequestPayloadJSON from './buildRequestPayloadJSON';
import {PayloadBody} from './types';

export default async function <T>(
	url: string,
	body: PayloadBody = {},
	headers: Record<string, string> = {},
	contentType: 'application/json' | 'multipart/form-data',
	files?: FileList,
	filesKey?: string | undefined,
): Promise<T> {
	const requestPayload = contentType === 'application/json'
		? buildRequestPayloadJSON(body)
		: buildRequestPayloadMultipartForm(body, files, filesKey);

	const allHeaders = {
		...headers,
	};

	if (contentType !== 'multipart/form-data') {
		allHeaders['Content-Type'] = contentType;
	}

	const options: RequestInit = {
		headers: allHeaders,
		method: 'POST',
		body: requestPayload,
	};

	return request<T>(url, options);
}
