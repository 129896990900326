import post from '../helpers/request/post';
import {event__uploadEventImages as targetURL} from '../endpointURLs';

type event__uploadEventImages = {
    eventId: string;
    noWatermark: boolean;
};

export default function (
	payload: event__uploadEventImages,
	headers = {},
	files: FileList
) {
	return post(targetURL, payload, headers, 'multipart/form-data', files, 'images');
}