<template>
    <div>
        <h1>Nuolaidos</h1>

        <table>
            <thead>
                <tr>
                    <th>Kodas</th>
                    <th>Tipas</th>
                    <th>Procentai</th>
                    <th>Pabaigos data</th>
                    <th>Like panaudojimai</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <input
                            v-model='newDiscount.code'
                            type='text'
                        />
                    </td>

                    <td>
                        <select v-model="newDiscount.type">
                            <option value="standard">
                                Iprastas
                            </option>

                            <option value="no-shipping">
                                Be siuntimo mokescio
                            </option>
                        </select>
                    </td>

                    <td>
                        <input
                            v-model='newDiscount.discountAmountPercentage'
                            type='number'
                            step="0.01"
                        />
                    </td>

                    <td>
                        <input
                            v-model='newDiscount.endDate'
                            type='date'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newDiscount.remainingUses'
                            type='number'
                            step="1"
                        />
                    </td>

                    <td>
                        <button @click='createDiscount'>
                            Sukurti
                        </button>
                    </td>
                </tr>

                <template
                    v-for='discount in discounts'
                    :key='discount._id'
                >
                    <tr>
                        <td>{{ discount.code }}</td>
                        <td>{{ discount.type }}</td>
                        <td>{{ discount.discountAmountPercentage }}</td>
                        <td>{{ discount.endDate }}</td>
                        <td>{{ discount.remainingUses }}</td>

                        <td>
                            <button @click='deleteDiscount(discount)'>
                                Trinti
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import formatDate from '@/helpers/shared/date/formatDate';
    import discount__getAll from '@/SDK/requests/discount__getAll';
    import discount__delete from '@/SDK/requests/discount__delete';
    import discount__create from '@/SDK/requests/discount__create';

    const discounts = ref([]);

    const newDiscount = ref({
        code: undefined,
        type: 'standard',
        discountAmountPercentage: 10,
        endDate: formatDate(new Date()),
        remainingUses: 0,
    });

    async function loadDiscounts() {
        discounts.value = (await discount__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    }

    function resetCreateDiscountForm() {
        newDiscount.value.code = undefined;
        newDiscount.value.type = 'standard';
        newDiscount.value.discountAmountPercentage = 10;
        newDiscount.value.endDate = formatDate(new Date());
        newDiscount.value.remainingUses = 0;
    }

    onBeforeMount(loadDiscounts);

    async function deleteDiscount(discount) {
        await discount__delete({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            discountID: discount._id,
        });

        loadDiscounts();
    }

    async function createDiscount() {
        await discount__create({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            ...newDiscount.value,
        });

        resetCreateDiscountForm();
        loadDiscounts();
    }
</script>
