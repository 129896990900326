<template>
    <div>
        <h1>Konstantos</h1>

        <table>
            <thead>
                <tr>
                    <th>Pavadinimas</th>
                    <th>Reiksme</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for='constant in constants'
                    :key='constant._id'
                >
                    <td>
                        {{ constantKeyToTranslationMap[constant.key] }}
                    </td>

                    <td>
                        <input
                            type='text'
                            v-model='constant.value'
                            @input='constant.isDirty = true'
                        />
                    </td>

                    <td>
                        <button
                            v-if='constant.isDirty'
                            @click='updateConstant(constant)'
                        >
                            Atnaujinti
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import constant__getAll from '../SDK/requests/constant__getAll';
    import constant__update from '../SDK/requests/constant__update';

    const constantKeyToTranslationMap = {
        videoId: 'YT Video ID',
        phone: 'Mob. nr.',
        email: 'El. pastas',
        URLFacebook: 'Facebook page nuoroda',
        URLInstagram: 'Instagram page nuoroda',
        shipping: 'Siuntimo mokestis(procentais, naudoti taska, ne kableli)'
    };

    const constants = ref([]);

    onBeforeMount(async () => {
        constants.value = (await constant__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    });

    function updateConstant(updatedConstant) {
        updatedConstant.isDirty = false;

        constant__update({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            constantId: updatedConstant._id,
            ...updatedConstant,
        });
    }
</script>
