import del from '../helpers/request/del';
import {eventCatalogue__delete as targetURL} from '../endpointURLs';

type eventCatalogue__delete = {
    catalogueID: string;
};

export default function (
	payload: eventCatalogue__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}