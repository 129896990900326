import del from '../helpers/request/del';
import {faq__delete as targetURL} from '../endpointURLs';

type faq__delete = {
    faqId: string;
};

export default function (
	payload: faq__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}