import del from '../helpers/request/del';
import {event__deleteImage as targetURL} from '../endpointURLs';

type event__deleteImage = {
    eventID: string;
    imageID: string;
};

export default function (
	payload: event__deleteImage,
	headers = {},
) {
	return del(targetURL, payload, headers);
}