import put from '../helpers/request/put';
import {translation__update as targetURL} from '../endpointURLs';

type translation__update = {
    translationId: string;
    key: string;
    en: string;
    lt: string;
};

export default function (
	payload: translation__update,
	headers = {},
) {
	return put(targetURL, payload, headers, 'application/json');
}