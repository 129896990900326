<template>
    <div>
        <h1>Uzsakymai</h1>

        <div style="display: flex; align-items: center; margin-bottom: 40px;">
            <input
                v-model="searchOrderID"
                type="text"
                placeholder="Uzsakymo ID"
            />

            <button @click="findOrderByID">
                Ieskoti
            </button>
        </div>

        <table>
            <thead>
                <tr>
                    <td>Uzsakymo ID</td>
                    <td>Uzsakymo data</td>
                    <td>Bendra info</td>
                    <td>Kitas addr</td>
                    <td>Kontaktai</td>
                    <td>Imone</td>
                </tr>
            </thead>

            <tbody>
                <template
                    v-for='order in sortedOrders'
                    :key='order._id'
                >
                    <tr>
                        <td>
                            <p>{{ order._id }}</p>

                            <p>(<a :href="`https://api.tavoakimirka.lt/zips/${order.zipFileName}`">
                                Failu zip
                            </a>)</p>
                        </td>

                        <td>
                            <p>{{ order.createdAt }}</p>
                            <p>{{ order.createdAtTime }}</p>
                        </td>

                        <td>
                            <p>Nuolaidos kodas: {{ order.discountCode }}</p>
                            <p>Vardas: {{ order.name }}</p>
                            <p>Pavarde: {{ order.surname }}</p>
                            <p>Salis: {{ order.country }}</p>
                            <p>Miestas: {{ order.city }}</p>
                            <p>Apskritis: {{ order.county }}</p>
                            <p>Gatve: {{ order.street }}</p>
                        </td>

                        <td>
                            <p>Siusti kitu adresu? {{ order.isSentAnotherAddress ? 'Taip' : 'Ne' }}</p>
                            <p>Kito addr. vardas: {{ order.anotherName }}</p>
                            <p>Kito addr. pavarde: {{ order.anotherSurname }}</p>
                            <p>Kito addr. salis: {{ order.anotherCountry }}</p>
                            <p>Kito addr. miestas: {{ order.anotherCity }}</p>
                            <p>Kito addr. apskritis: {{ order.anotherCounty }}</p>
                            <p>Kito addr. gatve: {{ order.anotherStreet }}</p>
                        </td>

                        <td>
                            <p>Email: {{ order.email }}</p>
                            <p>Tel.nr: {{ order.phone }}</p>
                        </td>

                        <td>
                            <p>Imones kodas: {{ order.companyCode }}</p>
                            <p>Imones pavadinimas: {{ order.companyName }}</p>
                            <p>Imones PVM kodas: {{ order.companyPvmCode }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <div style="padding-bottom: 100px;">
                                <div
                                    v-for="(productID, index) in order.productIDs"
                                    :key="productID"
                                    style="position: relative; display: inline-block; margin-bottom: 30px; margin-right: 30px;"
                                >
                                    <span
                                        v-if="order.products"
                                        style="position: absolute; top: 10px; left: 10px; background-color: #FFFFFF; padding: 5px; border-radius: 4px;"
                                    >
                                        {{ order.products[index].en.title }}
                                    </span>

                                    <span
                                        v-if="order.authors"
                                        style="position: absolute; bottom: 10px; left: 10px; background-color: #FFFFFF; padding: 5px; border-radius: 4px;"
                                    >
                                        {{ order.authors[index] }}
                                    </span>

                                    <img
                                        class='cart-mobile__item-image'
                                        :src="`${getThumbnailImg(productID, order.imageIDs[index])}`"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import {ref, onBeforeMount, onMounted, computed} from 'vue';
import order__lastHundred from '@/SDK/requests/order__lastHundred';
import order__findById from '@/SDK/requests/order__findById';
import {BASE_API_PATH} from '@/SDK/endpointURLs';
import product__getAll from '@/SDK/requests/product__getAll';

const orders = ref([]);
const searchOrderID = ref();
const products = ref([]);

const sortedOrders = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    return orders.value.sort((a, b) => {
        return `${a.createdAt} ${a.createdAtTime}` > `${b.createdAt} ${b.createdAtTime}`
            ? -1
            : 1;
    });
});

onMounted(async () => {
    products.value = (await product__getAll({
        projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
    })).data;
});

function getThumbnailImg(productID, imageID) {
    return productID.startsWith('no-event')
        ? `${BASE_API_PATH}/images/userUploads/${imageID}--thumbnail.jpg`
        : `${BASE_API_PATH}/images/events/${productID.split('__')[0]}/thumbnail/${imageID}.jpg`;
}

async function findOrderByID() {
    orders.value = [];

    orders.value[0] = (await order__findById({
        projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        orderID: searchOrderID.value,
    })).data;

    searchOrderID.value = '';
}

async function loadOrders() {
    orders.value = (await order__lastHundred({
        projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
    })).data;
}

onBeforeMount(loadOrders);
</script>

<style>
p {
    margin: 0;
}
</style>