<template>
    <div>
        <h1>Produktai</h1>

        <table>
            <thead>
                <tr>
                    <th>Nuotrauka</th>
                    <th>Kaina(centais)</th>
                    <th>LT Pavadinimas</th>
                    <th>EN Pavadinimas</th>
                    <th>LT Aprasymas</th>
                    <th>EN Aprasymas</th>
                    <th>LT Meta</th>
                    <th>EN Meta</th>
                    <th>Veiksmai</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <input
                            type="file"
                            @change='newProductImage = $event.target.files'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.priceCents'
                            type='number'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.lt.title'
                            type='text'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.en.title'
                            type='text'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.lt.description'
                            type='text'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.en.description'
                            type='text'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.lt.meta'
                            type='text'
                        />
                    </td>

                    <td>
                        <input
                            v-model='newProduct.en.meta'
                            type='text'
                        />
                    </td>

                    <td>
                        <button @click='createProduct'>
                            Sukurti
                        </button>
                    </td>
                </tr>

                <template
                    v-for='product in products'
                    :key='product._id'
                >
                    <tr>
                        <td>
                            <label>
                                <img :src='`${product.imageURL}?r=${product.imgRenderCount}`' />

                                <input
                                    type='file'
                                    style="opacity: 0;width: 0;height: 0;"
                                    @change='uploadNewProductPhoto(product, $event)'
                                />
                            </label>
                        </td>

                        <td>
                            <input
                                type='number'
                                v-model='product.priceCents'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.lt.title'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.en.title'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.lt.description'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.en.description'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.lt.meta'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <input
                                type='text'
                                v-model='product.en.meta'
                                @input='product.isDirty = true'
                            />
                        </td>

                        <td>
                            <button
                                v-if='product.isDirty'
                                @click='updateProduct(product)'
                            >
                                Atnaujinti
                            </button>

                            <button @click='deleteProduct(product)'>
                                Trinti
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import product__getAll from '@/SDK/requests/product__getAll';
    import product__create from '@/SDK/requests/product__create';
    import product__delete from '@/SDK/requests/product__delete';
    import {BASE_API_PATH} from '@/SDK/endpointURLs';
    import slugify from '@/helpers/shared/string/slugify';
    import product__uploadPhoto from '@/SDK/requests/product__uploadPhoto';
    import product__update from '@/SDK/requests/product__update';

    const products = ref([]);
    const newProductImage = ref();

    const newProduct = ref({
        priceCents: 1000,

        lt: {
            title: '',
            description: '',
            meta: '',
        },

        en: {
            title: '',
            description: '',
            meta: '',
        },
    });

    function updateProduct(updatedProduct) {
        updatedProduct.isDirty = false;

        product__update({
            ...updatedProduct,
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            productID: updatedProduct._id,
        });
    }

    async function uploadNewProductPhoto(product, event) {
        await product__uploadPhoto({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            productID: product._id,
        }, {}, event.target.files);

        product.imgRenderCount = typeof product.imgRenderCount === 'number'
            ? product.imgRenderCount++
            : 0;
    }

    async function loadProducts() {
        products.value = (await product__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data.map((product) => {
            return {
                ...product,
                imageURL: `${BASE_API_PATH}/images/products/${slugify(product.en.title)}.jpg`,
            };
        });
    }

    function resetCreateProductForm() {
        newProductImage.value = null;
        newProduct.value.priceCents = 1000;
        newProduct.value.lt.title = '';
        newProduct.value.lt.description = '';
        newProduct.value.lt.meta = '';
        newProduct.value.en.title = '';
        newProduct.value.en.description = '';
        newProduct.value.en.meta = '';
    }

    onBeforeMount(loadProducts);

    async function deleteProduct(product) {
        await product__delete({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            productID: product._id,
        });

        loadProducts();
    }

    async function createProduct() {
        await product__create({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            ...newProduct.value,
        }, {}, newProductImage.value);

        resetCreateProductForm();
        loadProducts();
    }
</script>
