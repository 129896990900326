import del from '../helpers/request/del';
import {admin__delete as targetURL} from '../endpointURLs';

type admin__delete = {
    adminID: string;
};

export default function (
	payload: admin__delete,
	headers = {},
) {
	return del(targetURL, payload, headers);
}