import post from '../helpers/request/post';
import {eventCatalogue__create as targetURL} from '../endpointURLs';

type eventCatalogue__create = {
    name: string;
    isInvisible: boolean;
    noWatermark: boolean;
};

export default function (
	payload: eventCatalogue__create,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}