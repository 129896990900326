import post from '../helpers/request/post';
import {admin__getAll as targetURL} from '../endpointURLs';

type admin__getAll = {
};

export default function (
	payload: admin__getAll,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}