import post from '../helpers/request/post';
import {admin__login as targetURL} from '../endpointURLs';

type admin__login = {
    username: string;
    password: string;
};

export default function (
	payload: admin__login,
	headers = {},
) {
	return post(targetURL, payload, headers, 'application/json');
}