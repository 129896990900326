import get from '../helpers/request/get';
import {event__getAllInCatalogue as targetURL} from '../endpointURLs';

type event__getAllInCatalogue = {
    targetCatalogueID?: string;
    targetNameSlug?: string;
};

export default function (
	payload: event__getAllInCatalogue,
	headers = {},
) {
	return get(targetURL, payload, headers);
}