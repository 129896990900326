import post from '../helpers/request/post';
import {product__uploadPhoto as targetURL} from '../endpointURLs';

type product__uploadPhoto = {
    productID: string;
};

export default function (
	payload: product__uploadPhoto,
	headers = {},
	files: FileList
) {
	return post(targetURL, payload, headers, 'multipart/form-data', files, 'photo');
}