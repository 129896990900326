import get from '../helpers/request/get';
import {discount__getAll as targetURL} from '../endpointURLs';

type discount__getAll = {
};

export default function (
	payload: discount__getAll,
	headers = {},
) {
	return get(targetURL, payload, headers);
}