<template>
    <div>
        <h1>Adminai</h1>

        <table>
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Role</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <th>
                        <input v-model='newAdmin.username' type='text' />
                    </th>

                    <th>
                        <input v-model='newAdmin.password' type='text' />
                    </th>

                    <th>
                        <select v-model='newAdmin.role'>
                            <option selected>
                                -
                            </option>

                            <option value='superAdmin'>
                                Super admin
                            </option>

                            <option value='photoImporter'>
                                Nuotrauku ikelejas
                            </option>

                            <option value="photoImporterAndOrdersEditor">
                                Nuotrauku ikelejas + Katalogo editorius
                            </option>
                        </select>
                    </th>

                    <th>
                        <button @click='createAdmin'>
                            Sukurti
                        </button>
                    </th>
                </tr>

                <template
                    v-for='admin in admins'
                    :key='admin.key'
                >
                    <tr>
                        <td>{{ admin.username }}</td>
                        <td>-</td>
                        <td>{{ admin.role }}</td>

                        <td>
                            <button @click='deleteAdmin(admin)'>
                                Trinti
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import {ref, onBeforeMount} from 'vue';
    import admin__create from '@/SDK/requests/admin__create';
    import admin__getAll from '@/SDK/requests/admin__getAll';
    import admin__delete from '@/SDK/requests/admin__delete';

    const admins = ref([]);

    const newAdmin = ref({
        username: '',
        password: '',
        role: '',
    });

    async function loadAdmins() {
        admins.value = (await admin__getAll({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
        })).data;
    }

    function resetCreateAdminForm() {
        newAdmin.value.username = '';
        newAdmin.value.password = '';
        newAdmin.value.role = '';
    }

    onBeforeMount(loadAdmins);

    async function deleteAdmin(admin) {
        await admin__delete({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            adminID: admin._id,
        });

        loadAdmins();
    }

    async function createAdmin() {
        await admin__create({
            projectKey: 'sKuRhAGsvIpuSgsUbmWZ',
            ...newAdmin.value,
        });

        resetCreateAdminForm();
        loadAdmins();
    }
</script>
