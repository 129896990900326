import get from '../helpers/request/get';
import {constant__getAll as targetURL} from '../endpointURLs';

type constant__getAll = {
};

export default function (
	payload: constant__getAll,
	headers = {},
) {
	return get(targetURL, payload, headers);
}