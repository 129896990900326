import request from './request';

type RequestParams = Record<string, string | number | undefined>;

export default async function <T>(
	url: string,
	params: RequestParams = {},
	headers: Record<string, string> = {}
): Promise<T> {
	const queryString = Object.entries(params)
		.filter(([_, value]) => {
			return value !== undefined && typeof value !== 'undefined';
		})
		.map(([key, value]) => {
			// @ts-ignore
			return encodeURIComponent(key) + '=' + encodeURIComponent(value);
		})
		.join('&');

	const options: RequestInit = {
		headers,
		method: 'DELETE',
	};

	const requestUrl = queryString
		? `${url}?${queryString}`
		: url;

	return request<T>(requestUrl, options);
}
